import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import {Root} from './styles';
import {WORK_HOURS, HOUR_START} from '../../shared/constants';

function getTimePercentOffset() {
  const startWorkDayMoment = moment()
    .startOf('day')
    .add(HOUR_START, 'hour');
  const endWorkDayMoment = moment()
    .startOf('day')
    .add(HOUR_START + WORK_HOURS, 'hour');
  const currentMoment = moment();
  if (
    currentMoment.isBefore(startWorkDayMoment) ||
    currentMoment.isAfter(endWorkDayMoment)
  ) {
    return null;
  }

  const totalDiff = endWorkDayMoment.diff(startWorkDayMoment);
  const currentDiff = currentMoment.diff(startWorkDayMoment);
  return (currentDiff / totalDiff) * 100;
}

const UPDATE_INTERVAL = 60 * 1000;

class CurrentTime extends React.Component {
  state = {
    offset: getTimePercentOffset()
  };

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState({offset: getTimePercentOffset()});
    }, UPDATE_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const {offset} = this.state;
    if (_.isNull(offset)) {
      return null;
    }

    return <Root top={offset} />;
  }
}

export default CurrentTime;
