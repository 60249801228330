import styled from 'styled-components';
import {GAP_XS, HOUR_COLUMN_WIDTH} from '../../shared/constants';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HourItem = styled.div`
  padding-top: ${GAP_XS}px;
  text-align: center;
  width: ${HOUR_COLUMN_WIDTH}px;
  flex-grow: 1;
`;
