import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import {getDayData, isToday, getNormalizedDayEvents} from '../../helpers';
import {HourItem, Container} from './styles';
import DayEvent from '../DayEvent';
import CurrentTime from '../CurrentTime';

class DayByHours extends React.Component {
  static propTypes = {
    date: PropTypes.string.isRequired,
    hourList: PropTypes.array.isRequired,
    normalizedDayEvents: PropTypes.array.isRequired,
    layout: PropTypes.string.isRequired
  };

  render() {
    const {date, normalizedDayEvents, hourList, layout} = this.props;

    return (
      <Container>
        {_.map(hourList, (item, i) => (
          <HourItem key={i} />
        ))}
        {_.map(normalizedDayEvents, (event, i) => (
          <DayEvent event={event} date={date} layout={layout} key={i} />
        ))}
        {isToday(date) && <CurrentTime />}
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let normalizedDayEvents = getNormalizedDayEvents(ownProps.date, state.events);
  let hourList = getDayData(ownProps.date);

  return {
    hourList,
    normalizedDayEvents
  };
};

export default connect(mapStateToProps)(DayByHours);
