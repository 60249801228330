import React from 'react';

import Layout from '../components/Layout';
import WeekGrid from '../components/WeekGrid';
import Header from '../components/Header';
import Footer from '../components/Footer';
import EventPopup from '../components/EventPopup';
import Seo from '../components/Seo';
import {handleAnalytics} from '../shared/analytics';
import {TRACK_NAVIGATION_WEEK} from '../shared/constants';

class WeekPage extends React.Component {
  componentDidMount() {
    handleAnalytics({event: TRACK_NAVIGATION_WEEK});
  }

  render() {
    return (
      <Layout>
        <Seo />
        <Header interval="week" />
        <WeekGrid />
        <Footer />
        <EventPopup />
      </Layout>
    );
  }
}

export default WeekPage;
