import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import {navigate} from 'gatsby';
import {Title} from './styles';
import {eventPopupUpdateAC, setDateAC} from '../../../shared/actions';

class DayTitle extends React.Component {
  static propTypes = {
    date: PropTypes.string.isRequired,
    setDate: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired
  };

  handleClick = () => {
    const {setDate, date, closePopup} = this.props;
    setDate(date);
    closePopup();
    navigate('/day/');
  };

  render() {
    const {date} = this.props;

    return (
      <Title onClick={this.handleClick}>
        <div>{moment(date).format('ddd')}</div>
        <div>{moment(date).format('DD')}</div>
      </Title>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setDate: date => dispatch(setDateAC(date)),
  closePopup: () => dispatch(eventPopupUpdateAC({isOpen: false}))
});

export default connect(null, mapDispatchToProps)(DayTitle);
