import styled from 'styled-components';
import {DAYS_IN_WEEK, HOUR_COLUMN_WIDTH} from '../../shared/constants';

export const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  animation: fadeIn 300ms;
`;

export const BlankHour = styled.div`
  flex: 0 0 ${HOUR_COLUMN_WIDTH}px;
`;

export const ContentRow = styled.div`
  flex-grow: 1;
  display: flex;
`;

export const RightColumn = styled.div`
  flex-grow: 1;
  display: flex;
`;

export const DayWrap = styled.div`
  flex: 1 1 ${100 / DAYS_IN_WEEK}%;
  display: flex;
  flex-direction: column;
`;
