import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Root, Content, Title, Description} from './styles';
import {
  HOUR_START,
  LAYOUT_DAY,
  LAYOUT_WEEK,
  WORK_HOURS
} from '../../shared/constants';
import {eventPopupUpdateAC, setDateAC} from '../../shared/actions';

function getOffsetPercent(isoStr) {
  let date = moment(isoStr);
  let start = date
    .clone()
    .startOf('day')
    .add(HOUR_START, 'hour');
  let timeDiff = date.diff(start, 'minutes') / 60;
  return (timeDiff / WORK_HOURS) * 100;
}

class DayEvent extends React.Component {
  static propTypes = {
    event: PropTypes.object.isRequired,
    openPopup: PropTypes.func.isRequired,
    setDate: PropTypes.func.isRequired,
    date: PropTypes.string.isRequired,
    layout: PropTypes.string.isRequired
  };

  handleClick = e => {
    const {event, openPopup, setDate, date} = this.props;
    setDate(date);
    openPopup({
      isOpen: true,
      description: event.description,
      color: _.get(event, 'color'),
      x: e.pageX,
      y: e.pageY
    });
    e.stopPropagation();
  };

  render() {
    const {layout} = this.props;
    const {title, description, color, start, end} = this.props.event;
    let top = getOffsetPercent(start);
    let height = getOffsetPercent(end) - getOffsetPercent(start);
    return (
      <Root top={top} height={height} bgColor={color}>
        {layout === LAYOUT_DAY && (
          <Content>
            <Title>{title}</Title>
            <Description
              dangerouslySetInnerHTML={{__html: description}}
              bgColor={color}
            />
          </Content>
        )}
        {layout === LAYOUT_WEEK && (
          <Content onClick={this.handleClick}>{title}</Content>
        )}
      </Root>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openPopup: payload => dispatch(eventPopupUpdateAC(payload)),
    setDate: date => dispatch(setDateAC(date))
  };
};

export default connect(null, mapDispatchToProps)(DayEvent);
