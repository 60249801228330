import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import {getDayData} from '../../helpers';
import {Root, HourItem} from './styles';

export default class HoursColumn extends React.Component {
  render() {
    let dayData = getDayData(moment());
    return (
      <Root>
        {_.map(dayData, (day, i) => (
          <HourItem key={i}>{moment(day).format('HH.mm')}</HourItem>
        ))}
      </Root>
    );
  }
}
