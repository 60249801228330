import styled from 'styled-components';
import {readableColor} from 'polished';
import {DEFAULT_EVENT_TEXT_COLOR, GAP_XS, GAP_S} from '../../shared/constants';

export const Root = styled.div`
  position: absolute;
  top: ${props => props.top}%;
  height: ${props => props.height}%;
  width: 100%;
  background: ${props => props.bgColor};
  color: ${({bgColor}) =>
    bgColor ? readableColor(bgColor) : DEFAULT_EVENT_TEXT_COLOR}};
`;

export const Content = styled.div`
  padding: ${GAP_XS}px;
  height: 100%;
`;

export const Title = styled.div`
  margin-bottom: ${GAP_S}px;
`;

export const Description = styled.div`
  color: ${({bgColor}) =>
    bgColor ? readableColor(bgColor) : DEFAULT_EVENT_TEXT_COLOR};

  a {
    color: ${({bgColor}) =>
      bgColor ? readableColor(bgColor) : DEFAULT_EVENT_TEXT_COLOR};
  }
`;
