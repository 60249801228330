import styled from 'styled-components';

const DOT_SIZE = 6;
const COLOR = '#000';

export const Root = styled.div`
  position: absolute;
  top: ${props => props.top}%;
  border-bottom: 1px solid ${COLOR};
  width: 100%;

  &:before {
    content: '';
    width: ${DOT_SIZE}px;
    height: ${DOT_SIZE}px;
    border-radius: 50%;
    position: absolute;
    top: -${DOT_SIZE / 2}px;
    left: -${DOT_SIZE / 2}px;
    background-color: ${COLOR};
  }
`;
