import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import DayByHours from '../DayByHours';
import {getWeekData} from '../../helpers';
import {Root, RightColumn, DayWrap, ContentRow, BlankHour} from './styles';
import HoursColumn from '../HoursColumn';
import DayTitle from '../DayByHours/DayTitle';
import {TitleRow} from '../commonStyles';
import {LAYOUT_WEEK} from '../../shared/constants';

class WeekGrid extends React.Component {
  static propTypes = {
    date: PropTypes.string.isRequired
  };

  render() {
    const {date} = this.props;
    let weekData = getWeekData(date);

    return (
      <Root>
        <TitleRow>
          <BlankHour />
          {_.map(weekData, (day, i) => (
            <DayWrap key={i}>
              <DayTitle date={day} />
            </DayWrap>
          ))}
        </TitleRow>
        <ContentRow>
          <HoursColumn />
          <RightColumn>
            {_.map(weekData, (day, i) => (
              <DayWrap key={i}>
                <DayByHours date={day} layout={LAYOUT_WEEK} />
              </DayWrap>
            ))}
          </RightColumn>
        </ContentRow>
      </Root>
    );
  }
}

const mapStateToProps = state => {
  return {
    date: state.date
  };
};

export default connect(mapStateToProps)(WeekGrid);
